// add default config
import {randomNum} from "@/helper/algorithm";
import store from "@/store/index"
                     // 2       5       3      4       8          2         30     6
                     // 1       0       3      2       4          2          6     2          5       40
let array = [
    {name: "双人毯", num: 1},
    {name: "跳绳", num: 3},
    {name: "单人毯", num: 3},
    {name: "唇膏", num: 2},
    {name: "单条毛巾", num: 3},
    {name: "组合毛巾", num: 2},
    {name: "玩偶", num: 15},
    {name: "杯子", num: 2},
    {name: "套圈", num: 29},
]
function randomField() {
    const str = 'abcdefghijklmnopqrstuvwxyz';
    let fieldStr = '';
    for (let index = 0; index < 10; index++) {
        fieldStr += str.split('')[randomNum(1, 27) - 1];
    }
    return `${fieldStr}${Date.now()}`;
}

export function addDefaultLotteryConfig() {
    let config = store.state.config
    for (let item of array) {
        console.log("item=" + item)
        const field = randomField();
        const data = {
            key: field,
            name: item.name
        };
        store.commit('setNewLottery', data);
        config[field] = Number(item.num);
        store.commit('setConfig', config)
    }

}

export function getCount() {
    return 31;
}

export function getNameList() {
    return [
        // {key: 1, name: "谢凯旋"},
        // {key: 2, name: "杨晓璐"},
        // {key: 3, name: "胡坤歌"},
        // {key: 4, name: "罗志成"},
        // {key: 5, name: "戴俊"},
        // {key: 6, name: "王鑫"},
        // {key: 7, name: "吴晏舟"},
        // {key: 8, name: "徐彦清"},
        // {key: 9, name: "王洪洋"},
        // {key: 10, name: "何鑫磊"},
        // {key: 10, name: "何鑫磊"},
        // {key: 10, name: "何鑫磊"},
        // {key: 10, name: "何鑫磊"},
        // {key: 10, name: "何鑫磊"},
        // {key: 10, name: "何鑫磊"},
        // {key: 10, name: "何鑫磊"},
        // {key: 10, name: "何鑫磊"},

    ];
}